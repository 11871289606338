import logo from "./logo.svg";
import "./App.css";

import { useState } from "react";

import { calculateBalance, nominalFormat, nextMonthBalance } from "./duidCalc";

function App() {
  const defaultPerSession = "15000";
  const defaultIsIncludeToday = true;
  const initialshouldBalance = calculateBalance(
    defaultPerSession,
    defaultIsIncludeToday
  );

  const [isChanged, setIsChanged] = useState(false);
  const [amountPerSession, setAmountPerSession] = useState(defaultPerSession);
  const [isIncludeToday, setIsIncludeToday] = useState(defaultIsIncludeToday);
  const [currentBalance, setCurrentBalance] = useState(initialshouldBalance);
  const [shouldBalance, setShouldBalance] = useState(initialshouldBalance);

  const handleChangeCurrentBalance = (e) => {
    setCurrentBalance(e.target.value.replace("Rp", "").replace(".", ""));
    setIsChanged(true);
  };

  const handleChangeAmountPerSession = (e) => {
    setAmountPerSession(e.target.value.replace("Rp", "").replace(".", ""));
    const newShouldBalance = calculateBalance(
      e.target.value.replace("Rp", "").replace(".", ""),
      isIncludeToday
    );
    setShouldBalance(newShouldBalance);
  };

  const handleChangeIsIncludeToday = () => {
    setIsIncludeToday(!isIncludeToday);
    const newShouldBalance = calculateBalance(
      amountPerSession,
      !isIncludeToday
    );
    setShouldBalance(newShouldBalance);
  };

  return (
    <div className="App">
      <header className="App-header">
        <p
          style={{
            fontSize: "40px",
            fontFamily: "monospace",
            marginTop: "-100px",
            marginBottom: "100px",
          }}
        >
          Hitung sisa duid kamu disini!
        </p>
        <label
          style={{
            marginLeft: "-5px",
            fontSize: "15px",
            textAlign: "left",
            fontFamily: "monospace",
            width: "50%",
          }}
        >
          Sisa duid kamu
        </label>
        <input
          onChange={handleChangeCurrentBalance}
          style={{
            fontSize: "17px",
            fontFamily: "monospace",
            textAlign: "right",
            height: "27px",
            width: "50%",
          }}
          value={
            currentBalance === 0
              ? ""
              : nominalFormat(isChanged ? currentBalance : "", "Rp")
          }
          type="tel"
        />
        <label
          style={{
            marginLeft: "-5px",
            fontSize: "15px",
            textAlign: "left",
            fontFamily: "monospace",
            width: "50%",
            marginTop: "20px",
          }}
        >
          Jumlah per sesi
        </label>
        <input
          onChange={(e) => {
            handleChangeAmountPerSession(e);
          }}
          style={{
            fontSize: "17px",
            fontFamily: "monospace",
            textAlign: "right",
            height: "27px",
            width: "50%",
          }}
          value={
            amountPerSession === 0 ? "" : nominalFormat(amountPerSession, "Rp")
          }
          type="tel"
        />
        <div style={{ display: "flex" }}>
          <p style={{ fontSize: "10px", fontFamily: "monospace" }}>
            termasuk hari ini?
          </p>
          <input
            type="checkbox"
            checked={isIncludeToday}
            onClick={() => {
              handleChangeIsIncludeToday();
            }}
            readOnly
          />
        </div>
        <p style={{ fontFamily: "monospace" }}>
          Sisa seharusnya: {shouldBalance}
        </p>
        <p style={{ fontFamily: "monospace", marginTop: "-10px" }}>
          Kamu bisa hedon:{" "}
          {nominalFormat(
            String(
              currentBalance.replace("Rp", "").replace(".", "") -
                shouldBalance.replace("Rp", "").replace(".", "")
            ),
            "Rp"
          )}
        </p>
        <p style={{ fontFamily: "monospace", marginTop: "-10px" }}>
          bulan depan/orang: {nextMonthBalance(amountPerSession)}
        </p>
      </header>
    </div>
  );
}

export default App;
