import moment from "moment";

const enumerateDaysBetweenDates = (startDate, endDate) => {
  let date = [];

  while (moment(startDate) <= moment(endDate)) {
    date.push(startDate);
    startDate = moment(startDate).add(1, "days").format("YYYY-MM-DD");
  }
  return date;
};

const calculateTotalEatSession = (dates, isIncludeToday) => {
  let total = 0;

  let iterator = 0;

  if (!isIncludeToday) {
    iterator = 1;
  }

  for (iterator; iterator < dates.length; iterator++) {
    const day = moment(dates[iterator]).format("dddd");

    if (day === "Saturday" || day === "Sunday") {
      total += 8;
    } else {
      total += 2;
    }
  }

  return total;
};

export const nominalFormat = (angka, prefix) => {
  let number_string = angka.replace(/[^,\d]/g, "").toString();

  let split = number_string.split(",");
  let sisa = split[0].length % 3;
  let rupiah = split[0].substr(0, sisa);
  let ribuan = split[0].substr(sisa).match(/\d{3}/gi);
  let separator = "";

  if (ribuan) {
    separator = sisa ? "." : "";
    rupiah += separator + ribuan.join(".");
  }

  rupiah = split[1] !== undefined ? rupiah + "," + split[1] : rupiah;

  const res = prefix === undefined ? rupiah : rupiah ? "Rp" + rupiah : "";

  if (angka < 0) {
    return "-" + res;
  }

  return res;
};

export const calculateBalance = (amountPerSession, isIncludeToday) => {
  const currentDate = moment().utcOffset(8).format("YYYY-MM-DD");

  const endDate = moment().utcOffset(8).endOf("month").format("YYYY-MM-DD");

  const dates = enumerateDaysBetweenDates(currentDate, endDate);

  const session = calculateTotalEatSession(dates, isIncludeToday);

  const nominal = nominalFormat((amountPerSession * session).toString(), "Rp");

  return nominal;
};

export const nextMonthBalance = (amountPerSession) => {
  const startDate = moment().utcOffset(8).startOf("month").format("YYYY-MM-DD");
  const endDate = moment().utcOffset(8).endOf("month").format("YYYY-MM-DD");

  const dates = enumerateDaysBetweenDates(startDate, endDate);

  const session = calculateTotalEatSession(dates, true);

  const nominal = nominalFormat(
    ((amountPerSession * session) / 2).toString(),
    "Rp"
  );

  return nominal;
};
